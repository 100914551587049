

































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope, CustomFormType, FundProjectApplyDto,
  FundProjectDto,
  FundProjectModifyApplyDto,
} from "@/api/appService";
import api from "@/api";

import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import DataDiffContainer from "@/components/DataItemDiff/DataItemDiffContainer.vue";
import DataItemDiff from "@/components/DataItemDiff/DataItemDiff.vue";
import DataDiffTitle from "@/components/DataItemDiff/DataDiffTitle.vue";
import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";

@Component({
  name: "FundProjectModifyApplyDetail",
  components: {
    DataDiffTitle,
    DataDiffContainer,
    DataItemDiff,
    ExportWord,
    AuditBlock,
    AttachmentsView,
    CustomFormDetailView
  },
})
export default class FundProjectModifyApplyDetail extends Vue {

  detail: FundProjectModifyApplyDto = {};
  dataId?: number;
  loadingProfile = true;

  get hostType() {
    return CustomFormType.FundProject;
  }

  created() {
    this.dataId = Number(this.$route.params.id);
  }

  getDetail() {
    return new Promise<FundProjectModifyApplyDto>((resolve, reject) => {
      if (!this.loadingProfile) {
        resolve(this.detail);
      } else {
        api.fundProjectModifyApply.get({id: this.dataId}).then((res) => {
          this.detail = res;
          this.loadingProfile = false;
          resolve(res);
        });
      }
    });
  }

  // 获取详情
  async fetchDetail() {
    api.fundProjectModifyApply.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };
    });
  }


  get auditApi(){
    return {
      canAudit: api.fundProjectModifyApply.canAudit,
      userAudit: api.fundProjectModifyApply.audit
    } as AuditApi
  }

}
